.custom-fileInput::-webkit-file-upload-button {
	padding: 10px;
	border-radius: 10px;
	cursor: pointer;
	color: #ffffff;

	background: rgb(2, 0, 36);
	background: linear-gradient(
		0deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(13, 88, 103, 1) 100%
	);
	border: none;
	transition: ease-in-out 0.07s;
}
.custom-fileInput:hover::-webkit-file-upload-button {
	transform: scale(0.95);
}
.custom-fileInputError {
	padding-bottom: 3px;
	border-bottom: 1px solid red;
}
