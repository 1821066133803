.customInputFiled {
	border: none;
	width: 100%;
	height: 50%;
	border-bottom: 1px solid #dcdcdc;
}
.customInputFiled:focus {
	outline: none;
	border-bottom: 2px solid #377bf9;
}
