/* Primary color Pixcua #00ACC8 */
.swal2-container {
	z-index: 20000;
}
.customInputNumber {
	padding: 5px;
	border-radius: 5px;
	border: 1px solid #e5e5e5;
}
.caja {
	background-color: aqua;
	display: inline-block;
	width: 100px;
	height: 500px;
	color: #e5e5e5;
	word-wrap: break-word;
	white-space: break-spaces;
}
.image-picture {
	width: 150px;
	overflow: hidden;
	border-radius: 5px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.img-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}
