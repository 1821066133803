.customButonComprobante {
	transition: all ease-in-out 0.2s;
	box-shadow: 1px 4px 10px 1px rgba(106, 106, 106, 0.391);
}
.customButonComprobante:hover {
	transition: all ease-in-out 0.2s;
	cursor: pointer;
	transform: scale(0.93);
	border: 3px solid #00bedb;
}
